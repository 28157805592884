import { Action } from "@ngrx/store";
import { Perfil } from "../../../../shared/model/perfil.model";
import { AuthContas } from "app/shared/model/auth.model";

export const LOGIN_START = "[Auth] LOGIN_START";
export const AUTHENTICATE_SUCCESS = "[Auth] AUTHENTICATE_SUCCESS";
export const AUTHENTICATE_FAIL = "[Auth] AUTHENTICATE_FAIL";
export const SIGNUP_START = "[Auth] SIGNUP_START";
export const CLEAR_ERROR = "[Auth] CLEAR_ERROR";
export const LOGOUT = "[Auth] LOGOUT";
export const AUTO_LOGIN = "[Auth] AUTO_LOGIN";
export const PASSWORD_RECOVER = "[Auth] PASSWORD RECOVER";
export const PASSWORD_SUCCESS = "[Auth] PASSWORD SUCCESS";
export const UPDATE_CONTA = "[Auth] UPDATE CONTA";

export class AuthenticateSuccess implements Action {
  readonly type = AUTHENTICATE_SUCCESS;

  constructor(
    public payload: {
      email: string;
      userId: string;
      token: string;
      expirationDate: Date;
      redirect: boolean;
      nome: string;
      newPassword: Boolean;
      contaAtual: AuthContas;
      contas: AuthContas[];
    }
  ) {}
}

export class Logout implements Action {
  readonly type = LOGOUT;
}

export class LoginStart implements Action {
  readonly type = LOGIN_START;

  constructor(public payload: { email: string; password: string }) {}
}

export class AuthenticateFail implements Action {
  readonly type = AUTHENTICATE_FAIL;

  constructor(public payload: string) {}
}

export class SignupStart implements Action {
  readonly type = SIGNUP_START;

  constructor(public payload: { email: string; password: string }) {}
}

export class ClearError implements Action {
  readonly type = CLEAR_ERROR;
}

export class AutoLogin implements Action {
  readonly type = AUTO_LOGIN;
}

export class PasswordRecover implements Action {
  readonly type = PASSWORD_RECOVER;

  constructor(public payload: string) {}
}

export class PasswordRecoverSuccess implements Action {
  readonly type = PASSWORD_SUCCESS;
}

export class UpdateConta implements Action {
  readonly type = UPDATE_CONTA;

  constructor(public payload: AuthContas) {}
}

export type AuthActions =
  | AuthenticateSuccess
  | Logout
  | LoginStart
  | AuthenticateFail
  | SignupStart
  | ClearError
  | AutoLogin
  | PasswordRecover
  | PasswordRecoverSuccess
  | UpdateConta;
