import { Component, Output, EventEmitter, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { LayoutService } from "../services/layout.service";
import { ConfigService } from "../services/config.service";

import * as AuthActions from "../../pages/content/auth/store/auth.actions";
import * as fromApp from "../../store/app.reducer";
import { Store } from "@ngrx/store";

import { Subscription } from "rxjs";

import { Auth } from "../model/auth.model";
import { ContaService } from "../services/conta.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  nomePagina = "";
  currentLang = "en";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  public isCollapsed = true;
  @Output()
  layoutSub: Subscription;
  toggleHideSidebar = new EventEmitter<Object>();
  usuarioAuth: Auth;
  public config: any = {};
  private authSub: Subscription;

  constructor(
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private store: Store<fromApp.AppState>,
    private _contaService: ContaService
  ) {
    // const browserLang: string = translate.getBrowserLang();
    translate.use("pt");
    this.layoutSub = layoutService.changeEmitted$.subscribe((direction) => {
      const dir = direction.direction;
      if (dir === "rtl") {
        this.placement = "bottom-left";
      } else if (dir === "ltr") {
        this.placement = "bottom-right";
      }
    });
  }

  ngOnInit() {
    this.config = this.configService.templateConf;
    var paginaAtual = window.location.href;

    if (paginaAtual.indexOf("regulatorios") > -1) {
      this.nomePagina = "Regulatórios";
    } else {
      this.nomePagina = "Teste";
    }

    this.authSub = this.store.select("auth").subscribe((state) => {
      if (state.usuarioAuth && state.usuarioAuth.contaAtual) {
        this.usuarioAuth = state.usuarioAuth;
      }
    });
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }

    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }

  ngAfterViewInit() {
    if (this.config.layout.dir) {
      const dir = this.config.layout.dir;
      if (dir === "rtl") {
        this.placement = "bottom-left";
      } else if (dir === "ltr") {
        this.placement = "bottom-right";
      }
    }
  }

  ChangeLanguage(language: string) {
    this.translate.use(language);
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  toggleNotificationSidebar() {
    this.layoutService.emitChange(true);
  }

  toggleSidebar() {
    const appSidebar = document.getElementsByClassName("app-sidebar")[0];
    if (appSidebar.classList.contains("hide-sidebar")) {
      this.toggleHideSidebar.emit(false);
    } else {
      this.toggleHideSidebar.emit(true);
    }
  }
  logout() {
    this.store.dispatch(new AuthActions.Logout());
  }

  changeConta(e) {
    const contaAtual = this.usuarioAuth.contas.find((conta) => conta.conta._id === e.conta._id);
    const auth = { ...this.usuarioAuth } as Auth;
    auth.contaAtual = contaAtual;
    this._contaService.alteraConta(auth);
    window.location.reload();
  }
}
