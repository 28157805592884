import { Action } from "@ngrx/store";
import { Regional } from "../../../../shared/model/regional.model";

export const FETCH_REGIONAIS = "[REGIONAIS] = FETCH REGIONAIS";
export const SET_REGIONAIS = "[REGIONAIS] = SET REGIONAIS";
export const SET_REGIONAL = "[REGIONAIS] = SET REGIONAL";
export const REMOVE_REGIONAL = "[REGIONAIS] = REMOVE REGIONAL";
export const TOGGLE_FILTROREGIONAIS = "[Regionais] = TOGGLE FILTROS REGIONAIS";
export const ADD_REGIONAL = "[Regionais] = ADD REGIONAL";
export const UPDATE_REGIONAL = "[REGIONAIS] = UPDATE REGIONAL";

export class FetchRegionais implements Action {
  readonly type = FETCH_REGIONAIS;
}

export class SetRegionais implements Action {
  readonly type = SET_REGIONAIS;

  constructor(public payload: Regional[]) {}
}

export class SetRegional implements Action {
  readonly type = SET_REGIONAL;

  constructor(public payload: Regional) {}
}

export class RemoveRegional implements Action {
  readonly type = REMOVE_REGIONAL;
}

export class ToggleFiltro implements Action {
  readonly type = TOGGLE_FILTROREGIONAIS;
}

export class AddRegional implements Action {
  readonly type = ADD_REGIONAL;

  constructor(public payload: Regional) {}
}

export class PutRegional implements Action {
  readonly type = UPDATE_REGIONAL;
  constructor(public payload: Regional) {}
}

export type RegionaisActions = FetchRegionais | SetRegionais | SetRegional | RemoveRegional | ToggleFiltro | AddRegional | PutRegional;
