import { Component, OnInit, ViewChild, ElementRef, Renderer2, AfterViewInit } from "@angular/core";

import { ROUTES } from "./sidebar-routes.config";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { customAnimations } from "../animations/custom-animations";
import { ConfigService } from "../services/config.service";

import * as fromApp from "../../store/app.reducer";
import { Store } from "@ngrx/store";
import { RouteInfo } from "./sidebar.metadata";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
  animations: customAnimations,
})
export class SidebarComponent implements OnInit, AfterViewInit {
  @ViewChild("toggleIcon") toggleIcon: ElementRef;
  public menuItems: any[];
  depth: number;
  activeTitle: string;
  activeTitles: string[] = [];
  expanded: boolean;
  nav_collapsed_open = false;
  logoUrl = "assets/img/logo.png";
  public config: any = {};

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private configService: ConfigService,
    private store: Store<fromApp.AppState>
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
      this.expanded = true;
    }
  }

  ngOnInit() {
    this.store.select("auth").subscribe(({ usuarioAuth: usuario }) => {
      if (usuario) {
        let rotas = new Array(ROUTES.length);
        for (let index = 0; index < ROUTES.length; index++) {
          const menu = ROUTES[index];
          rotas[index] = {
            ...menu,
            submenu: menu.submenu.slice(),
          };
        }
        const acessosNegados = usuario.contaAtual.perfil.rotinas.filter((it) => it.leitura === "nao");
        if (acessosNegados.length > 0) {
          for (const rotina of acessosNegados) {
            rotas.map((rota, index) => {
              const indexSub = rota.submenu.findIndex((it) => it.rotina === rotina.descricao);
              if (indexSub > -1) {
                rotas[index].submenu.splice(indexSub, 1);
              }
            });
          }
        }
        this.menuItems = rotas;
      }
    });
    this.config = this.configService.templateConf;

    if (this.config.layout.sidebar.backgroundColor === "white") {
      this.logoUrl = "assets/img/logo-dark.png";
    } else {
      this.logoUrl = "assets/img/logo.png";
    }
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   if (this.config.layout.sidebar.collapsed != undefined) {
    //     if (this.config.layout.sidebar.collapsed === true) {
    //       this.expanded = false;
    //       this.renderer.addClass(
    //         this.toggleIcon.nativeElement,
    //         "ft-toggle-left"
    //       );
    //       this.renderer.removeClass(
    //         this.toggleIcon.nativeElement,
    //         "ft-toggle-right"
    //       );
    //       this.nav_collapsed_open = true;
    //     } else if (this.config.layout.sidebar.collapsed === false) {
    //       this.expanded = true;
    //       this.renderer.removeClass(
    //         this.toggleIcon.nativeElement,
    //         "ft-toggle-left"
    //       );
    //       this.renderer.addClass(
    //         this.toggleIcon.nativeElement,
    //         "ft-toggle-right"
    //       );
    //       this.nav_collapsed_open = false;
    //     }
    //   }
    // }, 0);
  }

  toggleSlideInOut() {
    this.expanded = !this.expanded;
  }

  handleToggle(titles) {
    this.activeTitles = titles;
  }

  // NGX Wizard - skip url change
  ngxWizardFunction(path: string) {
    if (path.indexOf("forms/ngx") !== -1) this.router.navigate(["forms/ngx/wizard"], { skipLocationChange: false });
  }
}
