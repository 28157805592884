import { Perfil, Rotinas } from "../../../../shared/model/perfil.model";
import * as PerfilActions from "./perfil.actions";

export interface State {
  perfis: Perfil[];
  perfil: Perfil;
  indexRotinaEdit: number;
  loading: boolean;
  filtroPerfil: boolean;
}

const initialState: State = {
  perfis: [],
  perfil: null,
  indexRotinaEdit: -1,
  loading: false,
  filtroPerfil: true,
};

export function parfilReducer(state = initialState, action: PerfilActions.PerfilActions) {
  switch (action.type) {
    case PerfilActions.FETCH_PERFIS || PerfilActions.FETCH_PERFIL_EDIT:
      return {
        ...state,
        loading: true,
      };

    case PerfilActions.SET_PERFIS:
      return {
        ...state,
        loading: false,
        perfis: action.payload,
      };
    case PerfilActions.SET_PERFIL:
      return {
        ...state,
        perfil: action.payload,
        loading: false,
      };
    case PerfilActions.TOGGLE_FILTRO_PERFIL:
      return {
        ...state,
        filtroPerfil: !state.filtroPerfil,
      };
    case PerfilActions.START_EDIT_ROTINA:
      return {
        ...state,
        indexRotinaEdit: action.payload,
      };
    case PerfilActions.STOP_EDIT_ROTINA:
      return {
        ...state,
        indexRotinaEdit: -1,
      };
    case PerfilActions.UPDATE_ROTINA:
      const rotina = state.perfil.rotinas[state.indexRotinaEdit];
      const updatedRotina = {
        ...rotina,
        ...action.payload,
      };
      const rotinas = [...state.perfil.rotinas];
      rotinas[state.indexRotinaEdit] = updatedRotina;

      return {
        ...state,
        perfil: {
          ...state.perfil,
          rotinas: [...rotinas],
        },
      };

    case PerfilActions.REMOVE_PERFIL:
      return {
        ...state,
        perfil: null,
      };
    case PerfilActions.ADD_PERFIL_SUCCESS:
      return {
        ...state,
        perfil: null,
        indexRotinaEdit: -1,
        parfis: [...state.perfis, action.payload],
      };
    case PerfilActions.UPDATE_PERFIL:
      const indexPerfil = state.perfis.findIndex((it) => it._id === action.payload._id);
      const perfis = [...state.perfis];
      perfis[indexPerfil] = action.payload;

      return {
        ...state,
        indexRotinaEdit: -1,
        perfil: null,
        parfis: perfis,
      };
    default:
      return state;
  }
}
