import { Auth } from "../../../../shared/model/auth.model";
import * as AuthAction from "./auth.actions";

export interface State {
  usuarioAuth: Auth;
  authError: string;
  loading: boolean;
  passwordRecover: boolean;
}

const initialState = {
  usuarioAuth: null,
  authError: null,
  loading: false,
  passwordRecover: null,
};

export function authReducer(state = initialState, action: AuthAction.AuthActions) {
  switch (action.type) {
    case AuthAction.AUTHENTICATE_SUCCESS:
      const { email, userId, token, expirationDate, nome, newPassword, contaAtual: conta, contas } = action.payload;
      const usuarioAuth = new Auth(email, nome, newPassword, userId, conta, contas, token, expirationDate);
      return {
        ...state,
        authError: null,
        usuarioAuth,
        loading: false,
        passwordRecover: null,
      };
    case AuthAction.LOGOUT:
      state = undefined;
      return state;
    case AuthAction.LOGIN_START:
    case AuthAction.SIGNUP_START:
      return {
        ...state,
        authError: null,
        loading: true,
        passwordRecover: null,
      };
    case AuthAction.AUTHENTICATE_FAIL:
      return {
        ...state,
        usuarioAuth: null,
        authError: action.payload,
        loading: false,
        passwordRecover: null,
      };
    case AuthAction.CLEAR_ERROR:
      return {
        ...state,
        authError: null,
        passwordRecover: null,
      };
    case AuthAction.PASSWORD_SUCCESS:
      return {
        ...state,
        passwordRecover: true,
      };
    case AuthAction.UPDATE_CONTA:
      const usuarioAuthUpdated = { ...state.usuarioAuth, contaAtual: action.payload };

      return {
        ...state,
        usuarioAuth: usuarioAuthUpdated,
      };
    default:
      return state;
  }
}
