import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { HttpClient } from "@angular/common/http";

import * as DemandaActions from "./demanda.actions";
import { take, switchMap, map, catchError } from "rxjs/operators";
import { Demanda } from "../../../../shared/model/demanda.model";
import { environment } from "environments/environment";
import { UserDataService } from "app/shared/services/userData.service";

@Injectable()
export class DemandaEffects {
  @Effect()
  fetchDemandas = this.actions$.pipe(
    ofType(DemandaActions.FETCH_DEMANDAS),
    switchMap(({ payload }: DemandaActions.FetchDemanda) => {
      const usuario = this._userDataService.getUserData();
      return this.http.get<Demanda[]>(`${environment.url_backend}/demandas?_conta=${usuario.contaAtual.conta._id}&_tipo=${payload}`).pipe(
        map((demandas) => {
          if (demandas) {
            return new DemandaActions.SetDemandas(demandas);
          }
        })
      );
    })
  );

  @Effect()
  fetchDemanda = this.actions$.pipe(
    ofType(DemandaActions.FETCH_DEMANDA),
    switchMap((payload: DemandaActions.FetchDemanda) => {
      return this.http.get<Demanda>(`${environment.url_backend}/demandas?_demanda=${payload.payload}`).pipe(
        map((demanda) => {
          return new DemandaActions.SetDemanda(demanda);
        })
      );
    })
  );

  @Effect()
  putDemanda = this.actions$.pipe(
    ofType(DemandaActions.PUT_DEMANDA),
    switchMap((payload: DemandaActions.PutDemanda) => {
      return this.http.put<Demanda>(`${environment.url_backend}/demandas/${payload.payload._id}`, payload.payload);
    }),
    map((demanda) => {
      return new DemandaActions.SetDemanda(demanda);
    })
  );

  @Effect()
  finalizaDemanda = this.actions$.pipe(
    ofType(DemandaActions.FINALIZA_DEMANDA),
    switchMap((payload: DemandaActions.FinalizaDemanda) => {
      return this.http.post<Demanda>(`${environment.url_backend}/demandas/finaliza/${payload.payload._id}`, payload.payload);
    }),
    map(() => {
      return new DemandaActions.FimProcessamento();
    })
  );

  @Effect()
  aprovaDemanda = this.actions$.pipe(
    ofType(DemandaActions.APROVA_DEMANDA),
    switchMap((payload: DemandaActions.AprovaDemanda) => {
      return this.http.post<Demanda>(`${environment.url_backend}/demandas/aprova/${payload.payload.idDemanda}`, payload.payload);
    }),
    map(() => {
      return new DemandaActions.FimProcessamento();
    })
  );

  constructor(private actions$: Actions, private http: HttpClient, private _userDataService: UserDataService) {}
}
