import { Unidades } from "../../../../shared/model/unidades.model";
import * as UnidadesActions from "./unidades.actions";

export interface State {
  unidades: Unidades[];
  unidade: Unidades;
  loading: boolean;
  filtroGestao: boolean;
  concluido: boolean;
}

const initialState: State = {
  unidades: [],
  unidade: null,
  loading: false,
  filtroGestao: true,
  concluido: false,
};

export function unidadeReducer(state = initialState, action: UnidadesActions.UnidadesActions) {
  switch (action.type) {
    case UnidadesActions.FETCH_UNIDADES:
      return {
        ...state,
        loading: true,
        concluido: false,
      };
    case UnidadesActions.SET_UNIDADES:
      return {
        ...state,
        loading: false,
        unidades: [...action.payload],
        concluido: true,
      };
    case UnidadesActions.STOP_LOADING_UNIDADES:
      return {
        ...state,
        loading: false,
      };
    case UnidadesActions.SET_UNIDADE:
      return {
        ...state,
        loading: false,
        unidades: [...state.unidades, action.payload],
      };
    case UnidadesActions.SET_UNIDADE_EDIT:
      return {
        ...state,
        loading: false,
        unidade: action.payload,
      };
    case UnidadesActions.UPDATE_UNIDADE:
      const index = state.unidades.findIndex((elem, index, array) => {
        return action.payload._id === elem._id;
      });
      const updateUnidade = {
        ...state.unidades[index],
        ...action.payload,
      };
      const updateUnidades = [...state.unidades];
      updateUnidades[index] = updateUnidade;

      return {
        ...state,
        loading: false,
        unidades: updateUnidades,
        unidade: action.payload,
      };
    case UnidadesActions.REMOVE_UNIDADE_EDIT:
      return {
        ...state,
        loading: false,
        unidade: null,
      };
    case UnidadesActions.UPDATE_REVISAO_UNIDADE:
      return {
        ...state,
        loading: false,
        unidade: action.payload,
      };
    case UnidadesActions.TOGGLE_FILTRO_GESTAO:
      return {
        ...state,
        filtroGestao: !state.filtroGestao,
      };
    default:
      return state;
  }
}
