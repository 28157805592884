import { Marcas } from "../../../../shared/model/marcas.model";

import * as MarcasActions from "./marcas.actions";

export interface State {
  marcas: Marcas[];
  loading: boolean;
  filtroMarcas: boolean;
}

const initialState: State = {
  marcas: [],
  loading: false,
  filtroMarcas: true,
};

export function marcasReducer(state = initialState, action: MarcasActions.MarcasActions) {
  switch (action.type) {
    case MarcasActions.FETCH_MARCAS || MarcasActions.ADD_MARCA:
      return {
        ...state,
        loading: true,
      };
    case MarcasActions.SET_MARCAS:
      const marcas = action.payload.map((marca) => {
        return {
          ...marca,
          _id: marca._id,
        };
      });
      return {
        ...state,
        loading: false,
        marcas: [...marcas],
      };
    case MarcasActions.SET_MARCA:
      let sMarcas = state.marcas.slice();
      const index = sMarcas.findIndex((it) => it._id === action.payload._id);
      if (index > -1) {
        sMarcas[index] = action.payload;
      } else {
        sMarcas = [...sMarcas, action.payload];
      }

      return {
        ...state,
        marcas: sMarcas,
        loading: false,
      };
    case MarcasActions.TOGGLE_FILTROMARCAS:
      return {
        ...state,
        filtroMarcas: !state.filtroMarcas,
      };
    default:
      return state;
  }
}
