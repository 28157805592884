import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { HttpClient } from "@angular/common/http";
import * as UsuariosActions from "./usuarios.actions";
import { switchMap, map } from "rxjs/operators";
import { Usuarios } from "../../../../shared/model/usuarios.model";
import { environment } from "environments/environment";
import { Router } from "@angular/router";
import { UserDataService } from "app/shared/services/userData.service";

@Injectable()
export class UsuariosEffects {
  @Effect()
  fetchUsuarios = this.actions$.pipe(
    ofType(UsuariosActions.FETCH_USUARIOS),
    switchMap(() => {
      const usuario = this._userDataService.getUserData();
      return this.http.get<Usuarios[]>(`${environment.url_backend}/usuarios?_conta=${usuario.contaAtual.conta._id}`).pipe(
        map((usuarios) => {
          return usuarios.map((usuario) => {
            return {
              ...usuario,
              id: usuario._id,
            };
          });
        })
      );
    }),
    map((usuarios) => {
      return new UsuariosActions.SetUsuarios(usuarios);
    })
  );

  @Effect()
  fetchUsuarioEdit = this.actions$.pipe(
    ofType(UsuariosActions.FETCH_USUARIOS_EDIT),
    switchMap(({ payload }) => {
      return this.http.get<Usuarios>(`${environment.url_backend}/usuarios?_usuario=${payload}`);
    }),
    map((usuario) => {
      return new UsuariosActions.SetUsuarioEdit(usuario);
    })
  );

  @Effect()
  addUsuario = this.actions$.pipe(
    ofType(UsuariosActions.ADD_USUARIOS),
    switchMap((userData: UsuariosActions.AddUsuario) => {
      return this.http.post<Usuarios>(`${environment.url_backend}/usuarios`, userData.payload);
    }),
    map((usuario) => {
      return new UsuariosActions.AddUsuarioSuccess(usuario);
    })
  );

  @Effect()
  updateUsuario = this.actions$.pipe(
    ofType(UsuariosActions.UPDATE_USUARIO),
    switchMap((userData: UsuariosActions.UpdateUsuario) => {
      return this.http.put<Usuarios>(`${environment.url_backend}/usuarios/${userData.payload._id}`, userData.payload);
    }),
    map((usuario) => {
      return new UsuariosActions.UpdateUsuarioSuccess(usuario);
    })
  );

  @Effect({ dispatch: false })
  updateUsuarioPassword = this.actions$.pipe(
    ofType(UsuariosActions.UPDATE_USUARIO_PASSWORD),
    switchMap((userData: UsuariosActions.UpdateUsuario) => {
      return this.http.put<Usuarios>(`${environment.url_backend}/usuarios/${userData.payload._id}`, userData.payload);
    }),
    map(() => {
      this.router.navigate(["/gestao/unidades"]);
    })
  );

  constructor(private actions$: Actions, private http: HttpClient, private router: Router, private _userDataService: UserDataService) {}
}
