import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";

import * as RegulatoriosActions from "./regulatorios.actions";

import * as fromApp from "../../../../store/app.reducer";
import { switchMap, map, tap, catchError } from "rxjs/operators";
import { Regulatorios } from "../../../../shared/model/regulatorios.model";
import { environment } from "environments/environment";
import { UserDataService } from "app/shared/services/userData.service";

@Injectable()
export class RegulatoriosEffects {
  @Effect()
  fetchRegulatorios = this.actions$.pipe(
    ofType(RegulatoriosActions.FETCH_REGULATORIOS),
    switchMap(() => {
      const usuario = this._userDataService.getUserData();
      return this.http.get<Regulatorios[]>(`${environment.url_backend}/regulatorios?_conta=${usuario.contaAtual.conta._id}`);
    }),
    map((regulatorios) => {
      return regulatorios.map((regulatorio) => {
        return {
          ...regulatorio,
          id: regulatorio._id,
        };
      });
    }),
    map((regulatorios) => {
      return new RegulatoriosActions.SetRegulatorios(regulatorios);
    })
  );

  @Effect()
  fetchRegulatoriosEdit = this.actions$.pipe(
    ofType(RegulatoriosActions.FETCH_REGULATORIOS_EDIT),
    switchMap(({ payload }) => {
      return this.http.get<Regulatorios>(`${environment.url_backend}/regulatorios?_regulatorio=${payload}`);
    }),
    map((regulatorio) => {
      return new RegulatoriosActions.SetRegulatoriosEdit(regulatorio);
    })
  );

  @Effect()
  storeRegulatorios = this.actions$.pipe(
    ofType(RegulatoriosActions.ADD_REGULATORIO),
    switchMap(({ payload }) => {
      return this.http.post<Regulatorios>(`${environment.url_backend}/regulatorios`, payload);
    }),
    map((regulatorio) => {
      return new RegulatoriosActions.SetRegulatorio(regulatorio);
    })
  );

  @Effect()
  updateRegulatorio = this.actions$.pipe(
    ofType(RegulatoriosActions.PUT_REGULATORIO),
    switchMap(({ payload }) => {
      const { id } = payload;
      return this.http.put<Regulatorios>(`${environment.url_backend}/regulatorios/${id}`, payload);
    }),
    map((regulatorio) => {
      return new RegulatoriosActions.UpdateRegulatorio(regulatorio);
    })
  );

  constructor(private actions$: Actions, private http: HttpClient, private _userDataService: UserDataService) {}
}
