import { Action } from "@ngrx/store";
import { Perfil, Rotinas } from "../../../../shared/model/perfil.model";

export const FETCH_PERFIS = "[PERFIL] = FETCH PERFIS";
export const FETCH_PERFIL_EDIT = "[PERFIL] = FETCH PERFIS EDIT";
export const SET_PERFIS = "[PERFIL] = SET PERFIS";
export const SET_PERFIL = "[PERFIL] = SET PERFIL";
export const TOGGLE_FILTRO_PERFIL = "[PERFIL] = TOGGLE FILTRO PERFIL";
export const UPDATE_ROTINA = "[PERFIL] = UPDATE ROTINA";
export const START_EDIT_ROTINA = "[PERFIL] = START EDIT ROTINA";
export const STOP_EDIT_ROTINA = "[PERFIL] = STOP EDIT ROTINA";
export const REMOVE_PERFIL = "[PERFIL] = REMOVE PERFIL";
export const ADD_PERFIL = "[PERFIL] ADD PERFIL";
export const UPDATE_PERFIL = "[PERFIL] UPDATE PERFIL";
export const ADD_PERFIL_SUCCESS = "[PERFIL] ADD PERFIL SUCCESS";
export const UPDATE_PERFIL_SUCCESS = "[PERFIL] UPDATE PERFIL SUCCESS";

export class FetchPerfis implements Action {
  readonly type = FETCH_PERFIS;
}

export class SetPerfis implements Action {
  readonly type = SET_PERFIS;

  constructor(public payload: Perfil[]) {}
}

export class FetchPerfilEdit implements Action {
  readonly type = FETCH_PERFIL_EDIT;

  constructor(public payload: string) {}
}

export class SetPerfil implements Action {
  readonly type = SET_PERFIL;

  constructor(public payload: Perfil) {}
}

export class ToggleFilroPerfil implements Action {
  readonly type = TOGGLE_FILTRO_PERFIL;
}

export class UpdateRotina implements Action {
  readonly type = UPDATE_ROTINA;

  constructor(public payload: Rotinas) {}
}

export class StartEditRotina implements Action {
  readonly type = START_EDIT_ROTINA;

  constructor(public payload: number) {}
}

export class StopEditRotina implements Action {
  readonly type = STOP_EDIT_ROTINA;
}

export class RemovePerfil implements Action {
  readonly type = REMOVE_PERFIL;
}

export class AddPerfil implements Action {
  readonly type = ADD_PERFIL;
  constructor(public payload: Perfil) {}
}
export class AddPerfilSuccess implements Action {
  readonly type = ADD_PERFIL_SUCCESS;
  constructor(public payload: Perfil) {}
}

export class UpdatePerfil implements Action {
  readonly type = UPDATE_PERFIL;
  constructor(public payload: Perfil) {}
}
export class UpdatePerfilSuccess implements Action {
  readonly type = UPDATE_PERFIL_SUCCESS;
  constructor(public payload: Perfil) {}
}

export type PerfilActions =
  | FetchPerfis
  | SetPerfis
  | FetchPerfilEdit
  | SetPerfil
  | ToggleFilroPerfil
  | StartEditRotina
  | StopEditRotina
  | UpdateRotina
  | RemovePerfil
  | AddPerfil
  | AddPerfilSuccess
  | UpdatePerfil
  | UpdatePerfilSuccess;
