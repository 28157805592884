import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { HttpClient } from "@angular/common/http";

import * as MarcasActions from "./marcas.actions";
import { switchMap, map } from "rxjs/operators";
import { Marcas } from "../../../../shared/model/marcas.model";
import { environment } from "environments/environment";
import { UserDataService } from "app/shared/services/userData.service";

@Injectable()
export class MarcasEffects {
  @Effect()
  fetchMarcas = this.actions$.pipe(
    ofType(MarcasActions.FETCH_MARCAS),
    switchMap(() => {
      const usuario = this._userDataService.getUserData();
      return this.http.get<Marcas[]>(`${environment.url_backend}/marcas?_conta=${usuario.contaAtual.conta._id}`);
    }),
    map((marcas) => {
      return new MarcasActions.SetMarcas(marcas);
    })
  );

  @Effect()
  getMarca = this.actions$.pipe(
    ofType(MarcasActions.UPDATE_MARCA),
    switchMap((marcaData: MarcasActions.PutMarca) => {
      return this.http.put<Marcas>(`${environment.url_backend}/marcas/${marcaData.payload._id}`, marcaData.payload);
    }),
    map((marca) => {
      return new MarcasActions.SetMarca(marca);
    })
  );

  @Effect()
  addMarca = this.actions$.pipe(
    ofType(MarcasActions.ADD_MARCA),
    switchMap((marcaData: MarcasActions.AddMarca) => {
      return this.http.post<Marcas>(`${environment.url_backend}/marcas`, marcaData.payload);
    }),
    map((marca) => {
      return new MarcasActions.SetMarca(marca);
    })
  );

  constructor(private actions$: Actions, private http: HttpClient, private _userDataService: UserDataService) {}
}
