import { Usuarios } from "../../../../shared/model/usuarios.model";

import * as UsuariosActions from "./usuarios.actions";

export interface State {
  usuarios: Usuarios[];
  usuario: Usuarios;
  loading: boolean;
  filtroGestao: boolean;
}

const initialState: State = {
  usuarios: [],
  usuario: null,
  loading: false,
  filtroGestao: true,
};

export function usuariosReducer(state = initialState, action: UsuariosActions.UsuariosActions) {
  switch (action.type) {
    case UsuariosActions.FETCH_USUARIOS || UsuariosActions.ADD_USUARIOS || UsuariosActions.UPDATE_USUARIO:
      return {
        ...state,
        loading: true,
      };

    case UsuariosActions.SET_USUARIOS:
      return {
        ...state,
        usuarios: [...action.payload],
        loading: false,
      };
    case UsuariosActions.TOGGLE_FILTRO_USUARIOS:
      return {
        ...state,
        filtroGestao: !state.filtroGestao,
      };
    case UsuariosActions.ADD_USUARIOS_SUCCESS:
      const usuarios = [...state.usuarios, action.payload];
      return {
        ...state,
        usuarios,
      };
    case UsuariosActions.UPDATE_USUARIO_SUCCESS:
      const usuarioIndex = state.usuarios.findIndex((it) => it._id === action.payload._id);
      const updatedUsuario = {
        ...state.usuarios[usuarioIndex],
        ...action.payload,
      };

      const usuariosUpdated = [...state.usuarios];
      usuariosUpdated[usuarioIndex] = updatedUsuario;

      return {
        ...state,
        loading: false,
        usuarios: usuariosUpdated,
      };
    case UsuariosActions.REMOVE_USUARIO_EDIT:
      return {
        ...state,
        loading: false,
        usuario: null,
      };
    case UsuariosActions.SET_USUARIO_EDIT:
      return {
        ...state,
        usuario: action.payload,
      };

    default:
      return state;
  }
}
