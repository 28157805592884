import { Regulatorios } from "../../../../shared/model/regulatorios.model";
import * as RegulatoriosActions from "./regulatorios.actions";

export interface State {
  regulatorios: Regulatorios[];
  regulatorio: Regulatorios;
  loading: boolean;
  filtro: boolean;
}

const initialState: State = {
  regulatorios: [],
  regulatorio: null,
  loading: false,
  filtro: true,
};

export function regulatorioReducer(state = initialState, action: RegulatoriosActions.RegulatoriosActions) {
  switch (action.type) {
    case RegulatoriosActions.FETCH_REGULATORIOS:
      return {
        ...state,
        loading: true,
      };
    case RegulatoriosActions.SET_REGULATORIOS_EDIT:
      return {
        ...state,
        regulatorio: action.payload,
      };
    case RegulatoriosActions.SET_REGULATORIOS:
      return {
        ...state,
        loading: false,
        regulatorios: [...action.payload],
      };

    case RegulatoriosActions.SET_REGULATORIO:
      console.log("set regulatorio");
      return {
        ...state,
        regulatorios: [...state.regulatorios, action.payload],
      };

    case RegulatoriosActions.UPDATE_REGULATORIO:
      const index = state.regulatorios.findIndex((it) => it._id === action.payload._id);
      const updateRegulatorio = {
        ...state.regulatorios[index],
        ...action.payload,
      };
      const updateRegulatorios = [...state.regulatorios];
      updateRegulatorios[index] = updateRegulatorio;

      return {
        ...state,
        regulatorios: updateRegulatorios,
      };
    case RegulatoriosActions.TOGGLE_FILTRO:
      return {
        ...state,
        filtro: !state.filtro,
      };

    default:
      return state;
  }
}
