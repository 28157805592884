import { RouteInfo } from "./sidebar.metadata";

export const ROUTES: RouteInfo[] = [
  {
    path: "",
    title: "Gestão",
    icon: "ft-command",
    class: "has-sub",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/gestao/unidades",
        title: "Unidade",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        rotina: "Gestão Unidade",
      },
      {
        path: "/gestao/demanda",
        title: "Demanda Regulatória",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        rotina: "Demanda Regulatória",
      },
    ],
    rotina: "",
  },
  {
    path: "",
    title: "Cadastro",
    icon: "icon-doc",
    class: "has-sub",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/cadastro/regulatorios",
        title: "Regulatórios",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        rotina: "Cadastro de Regulatórios",
      },
      {
        path: "/cadastro/unidades",
        title: "Unidades",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        rotina: "Cadastro de Unidades",
      },
      {
        path: "/cadastro/grupos",
        title: "Grupos",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        rotina: "Cadastro de Grupos",
      },
      {
        path: "/cadastro/alcadas",
        title: "Alçadas",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        rotina: "Cadastro de Alçadas",
      },
      {
        path: "/cadastro/marcas",
        title: "Marcas",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        rotina: "Cadastro de Marcas",
      },
      {
        path: "/cadastro/regionais",
        title: "Regionais",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        rotina: "Cadastro de Regionais",
      },
      {
        path: "/cadastro/usuarios",
        title: "Usuarios",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        rotina: "Cadastro de Usuários",
      },
      {
        path: "/cadastro/perfis",
        title: "Perfil",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        rotina: "Cadastro de Perfil",
      },
    ],
    rotina: "",
  },
  {
    path: "",
    title: "Relatório",
    icon: "ft-file-text",
    class: "has-sub",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      {
        path: "/relatorios/relatorio",
        title: "Relatórios",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        rotina: "Relatórios",
      },
    ],
    rotina: "",
  },
];

// {
//     path: '', title: 'Menu Levels', icon: 'ft-align-left', class: 'has-sub', badge: '1', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
//     submenu: [
//         { path: 'javascript:;', title: 'Second Level', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
//         {
//             path: '', title: 'Second Level Child', icon: '', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
//             submenu: [
//                 { path: 'javascript:;', title: 'Third Level 1.1', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
//                 { path: 'javascript:;', title: 'Third Level 1.2', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
//             ]
//         },
//     ]
// },
