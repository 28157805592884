import { Action } from "@ngrx/store";
import { Demanda, Aprovacao } from "../../../../shared/model/demanda.model";

export const FETCH_DEMANDAS = "[Demandas] FETCH DEMANDAS";
export const FETCH_DEMANDA = "[Demandas] FETCH DEMANDA";
export const SET_DEMANDAS = "[Demandas] SET DEMANDAS";
export const SET_DEMANDA = "[Demandas] SET DEMANDA";
export const PUT_DEMANDA = "[Demandas] PUT DEMANDA";
export const REMOVE_DEMANDA = "[Demandas] REMOVE DEMANDA";
export const FINALIZA_DEMANDA = "[Demandas] FINALIZA DEMANDA";
export const APROVA_DEMANDA = "[Demandas] APROVA DEMANDA";
export const FIM_PROCESSAMENTO = "[Demandas] FIM PROCESSAMENTO";
export const TOGGLE_FILTRO_DEMANDA = "[Demandas] TOGGLE FILTRO DEMANDA";
export const RESETA_PROCESSAMENTO = "[Demandas] RESETA PROCESSAMENTO";

export class FetchDemandas implements Action {
  readonly type = FETCH_DEMANDAS;

  constructor(public payload: string = "") {}
}

export class SetDemandas implements Action {
  readonly type = SET_DEMANDAS;

  constructor(public payload: Demanda[]) {}
}

export class FetchDemanda implements Action {
  readonly type = FETCH_DEMANDA;

  constructor(public payload: string) {}
}

export class SetDemanda implements Action {
  readonly type = SET_DEMANDA;

  constructor(public payload: Demanda) {}
}

export class PutDemanda implements Action {
  readonly type = PUT_DEMANDA;
  constructor(public payload: Demanda) {}
}

export class RemoveDemanda implements Action {
  readonly type = REMOVE_DEMANDA;
}

export class FinalizaDemanda implements Action {
  readonly type = FINALIZA_DEMANDA;

  constructor(public payload: Demanda) {}
}

export class AprovaDemanda implements Action {
  readonly type = APROVA_DEMANDA;

  constructor(public payload: Aprovacao) {}
}

export class FimProcessamento implements Action {
  readonly type = FIM_PROCESSAMENTO;
}

export class ToggleFiltroDemanda implements Action {
  readonly type = TOGGLE_FILTRO_DEMANDA;
}

export class ResetaProcessamento implements Action {
  readonly type = RESETA_PROCESSAMENTO;
}

export type DemandaActions =
  | FetchDemandas
  | SetDemandas
  | FetchDemanda
  | SetDemanda
  | PutDemanda
  | RemoveDemanda
  | FinalizaDemanda
  | AprovaDemanda
  | FimProcessamento
  | ToggleFiltroDemanda
  | ResetaProcessamento;
