import { Routes } from "@angular/router";

//Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [
  {
    path: "",
    redirectTo: "/gestao/unidades",
    pathMatch: "full",
  },
  {
    path: "cadastro",
    loadChildren: () => import("../../pages/cadastro/cadastro.module").then((m) => m.CadastroModule),
  },
  {
    path: "gestao",
    loadChildren: () => import("../../pages/gestao/gestao.module").then((m) => m.GestaoModule),
  },
  {
    path: "relatorios",
    loadChildren: () => import("../../pages/relatorios/relatorios.module").then((m) => m.RelatoriosModule),
  },
];
