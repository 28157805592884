import { Relatorio } from "app/shared/model/relatorio.model";
import * as RelatoriosActions from "./relatorios.actions";

export interface State {
  relatorios: Relatorio[];
  relatorioError: string;
  excel: Boolean;
  loading: Boolean;
}

const initialState: State = {
  relatorios: [],
  relatorioError: null,
  excel: false,
  loading: false,
};

export function relatoriosReducer(state = initialState, action: RelatoriosActions.RelatoriosActions) {
  switch (action.type) {
    case RelatoriosActions.GERA_RELATORIOS_SUCCESS:
      return {
        ...state,
        relatorioError: null,
        relatorios: [...action.payload],
        loading: false,
      };
    case RelatoriosActions.GERA_RELATORIOS_FINISH:
      return {
        ...state,
        relatorioError: null,
        relatorios: [],
        loading: false,
      };
    case RelatoriosActions.GERA_RELATORIOS_FAIL:
      return {
        ...state,
        relatorios: [],
        relatorioError: action.payload,
        loading: false,
      };
    case RelatoriosActions.CLEAR_ERROR:
      return {
        ...state,
        relatorios: [],
        relatorioError: null,
      };
    case RelatoriosActions.GERA_EXCEL:
      return {
        ...state,
        excel: action.payload,
      };
    case RelatoriosActions.GERA_RELATORIOS:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
