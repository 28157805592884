import { ActionReducerMap } from "@ngrx/store";
import * as fromAuth from "../pages/content/auth/store/auth.reducer";
import * as fromRegulatorios from "../pages/cadastro/regulatorios/store/regulatorios.reducer";
import * as fromUnidades from "../pages/cadastro/unidades/store/unidades.reducer";
import * as fromShared from "../shared/store/shared.reducer";
import * as fromGrupos from "../pages/cadastro/grupos/store/grupos.reducer";
import * as fromAlcadas from "../pages/cadastro/alcadas/store/alcadas.reducer";
import * as fromDemandas from "../pages/gestao/demanda/store/demanda.reducer";
import * as fromUsuarios from "../pages/cadastro/usuarios/store/usuarios.reducer";
import * as fromMarcas from "../pages/cadastro/marcas/store/marcas.reducer";
import * as fromRegionais from "../pages/cadastro/regional/store/regional.reducer";
import * as fromPerfis from "../pages/cadastro/perfil/store/perfil.reducer";
import * as fromRelatorios from "../pages/relatorios/store/relatorios.reducer";

export interface AppState {
  auth: fromAuth.State;
  regulatorios: fromRegulatorios.State;
  unidades: fromUnidades.State;
  shared: fromShared.State;
  grupos: fromGrupos.State;
  alcadas: fromAlcadas.State;
  demandas: fromDemandas.State;
  usuarios: fromUsuarios.State;
  marcas: fromMarcas.State;
  regionais: fromRegionais.State;
  perfis: fromPerfis.State;
  relatorios: fromRelatorios.State;
}

export const appReducers: ActionReducerMap<AppState> = {
  auth: fromAuth.authReducer,
  regulatorios: fromRegulatorios.regulatorioReducer,
  unidades: fromUnidades.unidadeReducer,
  shared: fromShared.sharedReducer,
  grupos: fromGrupos.grupoReducer,
  alcadas: fromAlcadas.alcadaReducer,
  demandas: fromDemandas.demandaReducer,
  usuarios: fromUsuarios.usuariosReducer,
  marcas: fromMarcas.marcasReducer,
  regionais: fromRegionais.regionaisReducer,
  perfis: fromPerfis.parfilReducer,
  relatorios: fromRelatorios.relatoriosReducer,
};
