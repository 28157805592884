import { Action } from "@ngrx/store";
import { Relatorio, RelatorioDto } from "app/shared/model/relatorio.model";

export const GERA_RELATORIOS = "[RELATORIOS] GERA RELATORIOS";
export const GERA_RELATORIOS_SUCCESS = "[RELATORIOS] GERA RELATORIOS_SUCCESS";
export const GERA_RELATORIOS_FINISH = "[RELATORIOS] GERA RELATORIOS FINISH";
export const GERA_RELATORIOS_FAIL = "[RELATORIOS] GERA RELATORIOS FAIL";
export const CLEAR_ERROR = "[RELATORIOS] CLEAR_ERROR";
export const GERA_EXCEL = "[RELATORIOS] GERA_EXCEL";

export class GeraRelatorio implements Action {
  readonly type = GERA_RELATORIOS;

  constructor(public payload: RelatorioDto) {}
}

export class GeraRelatorioSuccess implements Action {
  readonly type = GERA_RELATORIOS_SUCCESS;

  constructor(public payload: Relatorio[]) {}
}

export class GeraRelatorioFinish implements Action {
  readonly type = GERA_RELATORIOS_FINISH;
}

export class GeraRelatorioFail implements Action {
  readonly type = GERA_RELATORIOS_FAIL;

  constructor(public payload: string) {}
}

export class ClearError implements Action {
  readonly type = CLEAR_ERROR;
}

export class GeraExcel implements Action {
  readonly type = GERA_EXCEL;

  constructor(public payload: Boolean) {}
}

export type RelatoriosActions = GeraRelatorio | GeraRelatorioSuccess | GeraRelatorioFinish | GeraRelatorioFail | ClearError | GeraExcel;
