import * as RegionalActions from "./regional.actions";
import { Regional } from "../../../../shared/model/regional.model";

export interface State {
  regionais: Regional[];
  loading: boolean;
  filtroRegionais: boolean;
}

const initialState: State = {
  regionais: [],
  loading: false,
  filtroRegionais: true,
};

export function regionaisReducer(state = initialState, action: RegionalActions.RegionaisActions) {
  switch (action.type) {
    case RegionalActions.FETCH_REGIONAIS || RegionalActions.ADD_REGIONAL:
      return {
        ...state,
        loading: true,
      };
    case RegionalActions.SET_REGIONAIS:
      const regionais = action.payload.map((regional) => {
        return {
          ...regional,
          _id: regional._id,
        };
      });
      return {
        ...state,
        loading: false,
        regionais: [...regionais],
      };
    case RegionalActions.SET_REGIONAL:
      let sRegionais = state.regionais.slice();
      const index = sRegionais.findIndex((it) => it._id === action.payload._id);
      if (index > -1) {
        sRegionais[index] = action.payload;
      } else {
        sRegionais = [...sRegionais, action.payload];
      }

      return {
        ...state,
        regionais: sRegionais,
        loading: false,
      };
    case RegionalActions.TOGGLE_FILTROREGIONAIS:
      return {
        ...state,
        filtroRegionais: !state.filtroRegionais,
      };
    default:
      return state;
  }
}
