import { Grupo } from "../../../../shared/model/grupo.model";
import * as GrupoActions from "./grupos.actions";

export interface State {
  grupos: Grupo[];
  grupo: Grupo;
  filtro: boolean;
  concluido: boolean;
}

const initialState: State = {
  grupos: [],
  grupo: null,
  filtro: true,
  concluido: false,
};

export function grupoReducer(state = initialState, action: GrupoActions.GruposActions) {
  switch (action.type) {
    case GrupoActions.FETCH_GRUPOS:
      return {
        ...state,
        concluido: false,
      };

    case GrupoActions.SET_GRUPO:
      return {
        ...state,
        grupos: [...state.grupos, action.payload],
        concluido: true,
      };
    case GrupoActions.SET_GRUPOS:
      return {
        ...state,
        grupos: [...action.payload],
        concluido: false,
      };
    case GrupoActions.SET_GRUPO_EDIT:
      return {
        ...state,
        grupo: action.payload,
        concluido: false,
      };

    case GrupoActions.UPDATE_GRUPO:
      const index = state.grupos.findIndex((elem, index, array) => {
        return action.payload.id === elem.id;
      });
      const updateGrupo = {
        ...state.grupos[index],
        ...action.payload,
      };
      const updateGrupos = [...state.grupos];
      updateGrupos[index] = updateGrupo;

      return {
        ...state,
        grupos: updateGrupos,
        concluido: true,
      };
    case GrupoActions.ADD_GRUPO:
      return {
        ...state,
        concluido: false,
      };
    case GrupoActions.TOOGLE_FILTRO:
      return {
        ...state,
        filtro: !state.filtro,
      };
    default:
      return state;
  }
}
