import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { HttpClient } from "@angular/common/http";

import { switchMap, map } from "rxjs/operators";
import { Perfil } from "../../../../shared/model/perfil.model";
import { environment } from "environments/environment";
import { Router } from "@angular/router";
import * as PerfilActions from "./perfil.actions";
import { UserDataService } from "app/shared/services/userData.service";

@Injectable()
export class PerfilEffects {
  @Effect()
  fetchPerfil = this.action$.pipe(
    ofType(PerfilActions.FETCH_PERFIS),
    switchMap(() => {
      const usuario = this._userDataService.getUserData();
      return this.http.get<Perfil[]>(`${environment.url_backend}/perfis?_conta=${usuario.contaAtual.conta._id}`);
    }),
    map((perfis) => {
      return new PerfilActions.SetPerfis(perfis);
    })
  );

  @Effect()
  fetchPerfilEdit = this.action$.pipe(
    ofType(PerfilActions.FETCH_PERFIL_EDIT),
    switchMap(({ payload }) => {
      return this.http.get<Perfil>(`${environment.url_backend}/perfis?_perfil=${payload}`);
    }),
    map((perfil) => {
      return new PerfilActions.SetPerfil(perfil);
    })
  );

  @Effect({ dispatch: false })
  addPerfil = this.action$.pipe(
    ofType(PerfilActions.ADD_PERFIL),
    switchMap((perfilData: PerfilActions.AddPerfil) => {
      return this.http.post<Perfil>(`${environment.url_backend}/perfis`, perfilData.payload);
    }),
    map((perfil) => {
      new PerfilActions.AddPerfilSuccess(perfil);
      this.router.navigate(["/cadastro/perfis"]);
    })
  );

  @Effect({ dispatch: false })
  updatePerfil = this.action$.pipe(
    ofType(PerfilActions.UPDATE_PERFIL),
    switchMap((perfilData: PerfilActions.UpdatePerfil) => {
      return this.http.put<Perfil>(`${environment.url_backend}/perfis/${perfilData.payload._id}`, perfilData.payload);
    }),
    map((perfil) => {
      new PerfilActions.UpdatePerfilSuccess(perfil);
      this.router.navigate(["/cadastro/perfis"]);
    })
  );

  constructor(private action$: Actions, private http: HttpClient, private router: Router, private _userDataService: UserDataService) {}
}
