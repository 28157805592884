import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { HttpClient } from "@angular/common/http";

import * as RelatoriosActions from "./relatorios.actions";
import { switchMap, map } from "rxjs/operators";
import { Relatorio } from "app/shared/model/relatorio.model";
import { environment } from "environments/environment";
import { of } from "rxjs";
import { UserDataService } from "app/shared/services/userData.service";

@Injectable()
export class RelatoriosEffects {
  @Effect()
  geraRelatorio = this.actions$.pipe(
    ofType(RelatoriosActions.GERA_RELATORIOS),
    switchMap((relatorioData: RelatoriosActions.GeraRelatorio) => {
      const usuario = this._userDataService.getUserData();
      return this.http.post<Relatorio[]>(
        `${environment.url_backend}/relatorios?_conta=${usuario.contaAtual.conta._id}`,
        relatorioData.payload
      );
    }),
    map((relatorio) => {
      if (relatorio.length > 0) {
        return new RelatoriosActions.GeraRelatorioSuccess(relatorio);
      }
      return new RelatoriosActions.GeraRelatorioFail("Não há dados para os parametros informados!");
    })
  );

  constructor(private actions$: Actions, private http: HttpClient, private _userDataService: UserDataService) {}
}
