import { Injectable } from "@angular/core";
import { Perfil } from "../model/perfil.model";
import { AESEncryptService } from "./AESEncrypt.Service";
import { AuthContas } from "../model/auth.model";
import { Conta } from "../model/conta.model";

@Injectable({ providedIn: "root" })
export class UserDataService {
  constructor(private _AESEncryptService: AESEncryptService, private aesService: AESEncryptService) {}

  getUserData(): any {
    const userStorageData = localStorage.getItem("userData");
    if (!userStorageData) return null;
    const data = this._AESEncryptService.decrypt(userStorageData);
    const userData: {
      email: string;
      nome: string;
      perfil: Perfil;
      newPassword: Boolean;
      id: string;
      conta: Conta;
      contas: AuthContas[];
      _token: string;
      _tokenExpirationDate: Date;
    } = JSON.parse(data);

    return userData;
  }

  removeData(): void {
    localStorage.removeItem("userData");
  }

  setUserData(userAuth): void {
    const data = this.aesService.encrypt(JSON.stringify(userAuth));
    localStorage.setItem("userData", data);
  }
}
