import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { HttpClient } from "@angular/common/http";
import { switchMap, map } from "rxjs/operators";

import { environment } from "environments/environment";
import * as RegionalActions from "./regional.actions";
import { Regional } from "../../../../shared/model/regional.model";
import { UserDataService } from "app/shared/services/userData.service";

@Injectable()
export class RegionalEffects {
  @Effect()
  fetchRegionais = this.actions$.pipe(
    ofType(RegionalActions.FETCH_REGIONAIS),
    switchMap(() => {
      const usuario = this._userDataService.getUserData();
      return this.http.get<Regional[]>(`${environment.url_backend}/regionais?_conta=${usuario.contaAtual.conta._id}`);
    }),
    map((regionais) => {
      return new RegionalActions.SetRegionais(regionais);
    })
  );

  @Effect()
  getRegionais = this.actions$.pipe(
    ofType(RegionalActions.UPDATE_REGIONAL),
    switchMap((regionalData: RegionalActions.PutRegional) => {
      return this.http.put<Regional>(`${environment.url_backend}/regionais/${regionalData.payload._id}`, regionalData.payload);
    }),
    map((regional) => {
      return new RegionalActions.SetRegional(regional);
    })
  );

  @Effect()
  addRegional = this.actions$.pipe(
    ofType(RegionalActions.ADD_REGIONAL),
    switchMap((regionalData: RegionalActions.AddRegional) => {
      return this.http.post<Regional>(`${environment.url_backend}/regionais`, regionalData.payload);
    }),
    map((regional) => {
      return new RegionalActions.SetRegional(regional);
    })
  );

  constructor(private actions$: Actions, private http: HttpClient, private _userDataService: UserDataService) {}
}
