import { Action } from "@ngrx/store";
import { Usuarios } from "../../../../shared/model/usuarios.model";

export const FETCH_USUARIOS = "[USUARIOS] = FETCH USUARIOS";
export const SET_USUARIOS = "[USUARIOS] = SET_USUARIOS";
export const ADD_USUARIOS = "[USUARIOS] = ADD_USUARIOS";
export const ADD_USUARIOS_SUCCESS = "[USUARIOS] = ADD_USUARIOS SUCCESS";
export const TOGGLE_FILTRO_USUARIOS = "[USUARIOS] = TOOGLE FILTRO USUARIOS";
export const FETCH_USUARIOS_EDIT = "[USUARIOS] = FETCH USUARIOS EDIT";
export const SET_USUARIO_EDIT = "[USUARIOS] = SET USUARIOS EDIT";
export const REMOVE_USUARIO_EDIT = "[USUARIOS] = REMOVE USUARIOS EDIT";
export const UPDATE_USUARIO = "[USUARIOS] = UPDATE USUARIOS";
export const UPDATE_USUARIO_SUCCESS = "[USUARIOS] = UPDATE USUARIOS SUCCESS";
export const UPDATE_USUARIO_PASSWORD = "[USUARIOS] = UPDATE USUARIOS PASSWORD";

export class FetchUsuarios implements Action {
  readonly type = FETCH_USUARIOS;
}

export class SetUsuarios implements Action {
  readonly type = SET_USUARIOS;

  constructor(public payload: Usuarios[]) {}
}

export class AddUsuario implements Action {
  readonly type = ADD_USUARIOS;

  constructor(public payload: Usuarios) {}
}

export class ToggleFilroUsuarios implements Action {
  readonly type = TOGGLE_FILTRO_USUARIOS;
}

export class FetchUsuarioEdit implements Action {
  readonly type = FETCH_USUARIOS_EDIT;

  constructor(public payload: string) {}
}

export class SetUsuarioEdit implements Action {
  readonly type = SET_USUARIO_EDIT;

  constructor(public payload: Usuarios) {}
}

export class AddUsuarioSuccess implements Action {
  readonly type = ADD_USUARIOS_SUCCESS;

  constructor(public payload: Usuarios) {}
}

export class UpdateUsuario implements Action {
  readonly type = UPDATE_USUARIO;

  constructor(public payload: Usuarios) {}
}

export class UpdateUsuarioPassword implements Action {
  readonly type = UPDATE_USUARIO_PASSWORD;

  constructor(public payload: Usuarios) {}
}

export class UpdateUsuarioSuccess implements Action {
  readonly type = UPDATE_USUARIO_SUCCESS;

  constructor(public payload: Usuarios) {}
}

export class RemoveUsuarioEdit implements Action {
  readonly type = REMOVE_USUARIO_EDIT;
}

export type UsuariosActions =
  | FetchUsuarios
  | SetUsuarios
  | AddUsuario
  | ToggleFilroUsuarios
  | FetchUsuarioEdit
  | SetUsuarioEdit
  | AddUsuarioSuccess
  | UpdateUsuario
  | UpdateUsuarioSuccess
  | RemoveUsuarioEdit
  | UpdateUsuarioPassword;
