import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

import { CoreModule } from "./core..module";
import { AppComponent } from "./app.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentComponent } from "./layouts/content/content.component";
import { RegulatoriosEffects } from "./pages/cadastro/regulatorios/store/regulatorios.effects";
import { environment } from "../environments/environment";

import * as fromApp from "./store/app.reducer";
import { UnidadesEffects } from "./pages/cadastro/unidades/store/unidades.effects";
import { SharedEffects } from "./shared/store/shared.effects";
import { GruposEffects } from "./pages/cadastro/grupos/store/grupos.effects";
import { AlcadasEffects } from "./pages/cadastro/alcadas/store/alcadas.effects";
import { DemandaEffects } from "./pages/gestao/demanda/store/demanda.effects";
import { UsuariosEffects } from "./pages/cadastro/usuarios/store/usuarios.effects";
import { MarcasEffects } from "./pages/cadastro/marcas/store/marcas.effects";
import { RegionalEffects } from "./pages/cadastro/regional/store/regional.effects";
import { ToastrModule } from "ngx-toastr";
import { AuthEffects } from "./pages/content/auth/store/auth.effects";
import { PerfilEffects } from "./pages/cadastro/perfil/store/perfil.effects";
import { RelatoriosEffects } from "./pages/relatorios/store/relatorios.effects";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent, FullLayoutComponent, ContentComponent],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot(fromApp.appReducers),
    EffectsModule.forRoot([
      RegulatoriosEffects,
      UnidadesEffects,
      SharedEffects,
      GruposEffects,
      AlcadasEffects,
      DemandaEffects,
      UsuariosEffects,
      MarcasEffects,
      RegionalEffects,
      AuthEffects,
      PerfilEffects,
      RelatoriosEffects,
    ]),
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
    PerfectScrollbarModule,
    CoreModule,
    SharedModule,
    ToastrModule.forRoot(),
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
