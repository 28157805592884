import { Action } from "@ngrx/store";
import { Regulatorios } from "../../../../shared/model/regulatorios.model";

export const SET_REGULATORIOS = "[Regulatorios] SET_REGULATORIOS";
export const SET_REGULATORIOS_EDIT = "[Regulatorios] SET_REGULATORIOS_EDIT";
export const FETCH_REGULATORIOS = "[Regulatorios] FETCH_REGULATORIOS";
export const FETCH_REGULATORIOS_EDIT = "[Regulatorios] FETCH_REGULATORIOS_EDIT";
export const ADD_REGULATORIO = "[Regulatorios] ADD_REGULATORIO";
export const PUT_REGULATORIO = "[Regulatorios] PUT_REGULATORIO";
export const SET_REGULATORIO = "[Regulatorios] SET_REGULATORIO";
export const UPDATE_REGULATORIO = "[Regulatorios] UPDATE_REGULATORIO";
export const STORE_REGULATORIOS = "[Regulatorios] STORE_REGULATORIOS";
export const TOGGLE_FILTRO = "[Regulatorios] TOGGLE FILTRO";

export class SetRegulatorios implements Action {
  readonly type = SET_REGULATORIOS;

  constructor(public payload: Regulatorios[]) {}
}
export class SetRegulatorio implements Action {
  readonly type = SET_REGULATORIO;

  constructor(public payload: Regulatorios) {}
}

export class SetRegulatoriosEdit implements Action {
  readonly type = SET_REGULATORIOS_EDIT;

  constructor(public payload: Regulatorios) {}
}

export class FetchRegulatoriosEdit implements Action {
  readonly type = FETCH_REGULATORIOS_EDIT;

  constructor(public payload: String) {}
}

export class FetchRegulatorios implements Action {
  readonly type = FETCH_REGULATORIOS;
}

export class AddRegulatorio implements Action {
  readonly type = ADD_REGULATORIO;

  constructor(public payload: Regulatorios) {}
}

export class UpdateRegulatorio implements Action {
  readonly type = UPDATE_REGULATORIO;

  constructor(public payload: Regulatorios) {}
}
export class PutRegulatorio implements Action {
  readonly type = PUT_REGULATORIO;

  constructor(public payload: Regulatorios) {}
}

export class StoreRegulatorios implements Action {
  readonly type = STORE_REGULATORIOS;
}

export class ToggleFiltro implements Action {
  readonly type = TOGGLE_FILTRO;
}

export type RegulatoriosActions =
  | SetRegulatorios
  | SetRegulatorio
  | SetRegulatoriosEdit
  | FetchRegulatorios
  | FetchRegulatoriosEdit
  | AddRegulatorio
  | UpdateRegulatorio
  | StoreRegulatorios
  | ToggleFiltro;
