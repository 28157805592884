import { Action } from "@ngrx/store";
import { Grupo } from "../../../../shared/model/grupo.model";

export const FETCH_GRUPOS = "[Grupos] FETCH GRUPOS";
export const SET_GRUPO = "[Grupos] SET GRUPO";
export const SET_GRUPOS = "[Grupos] SET GRUPOS";
export const ADD_GRUPO = "[Grupos] ADD GRUPOS";
export const PUT_GRUPO = "[Grupos] PUT GRUPOS";
export const UPDATE_GRUPO = "[Grupos] UPDATE GRUPOS";
export const FETCH_GRUPO_EDIT = "[Grupos] FETCH GRUPOS_EDIT";
export const SET_GRUPO_EDIT = "[Grupos] SET GRUPOS_EDIT";
export const TOOGLE_FILTRO = "[Grupos] TOOGLE_FILTRO";

export class FetchGrupos implements Action {
  readonly type = FETCH_GRUPOS;
}

export class SetGrupo implements Action {
  readonly type = SET_GRUPO;

  constructor(public payload: Grupo) {}
}

export class SetGrupos implements Action {
  readonly type = SET_GRUPOS;

  constructor(public payload: Grupo[]) {}
}

export class AddGrupo implements Action {
  readonly type = ADD_GRUPO;

  constructor(public payload: Grupo) {}
}

export class PutGrupo implements Action {
  readonly type = PUT_GRUPO;

  constructor(public payload: Grupo) {}
}

export class UpdateGrupo implements Action {
  readonly type = UPDATE_GRUPO;

  constructor(public payload: Grupo) {}
}

export class FetchGrupoEdit implements Action {
  readonly type = FETCH_GRUPO_EDIT;
  constructor(public payload: String) {}
}

export class SetGrupoEdit implements Action {
  readonly type = SET_GRUPO_EDIT;

  constructor(public payload: Grupo) {}
}

export class ToggleFiltro implements Action {
  readonly type = TOOGLE_FILTRO;
}

export type GruposActions = FetchGrupos | SetGrupos | SetGrupo | AddGrupo | PutGrupo | UpdateGrupo | FetchGrupoEdit | SetGrupoEdit | ToggleFiltro;
