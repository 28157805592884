import { Perfil } from "./perfil.model";
import { Conta } from "./conta.model";
import { Marcas } from "./marcas.model";
import { Unidades } from "./unidades.model";

export interface AuthContas {
  conta: Conta;
  status: string;
  perfil: Perfil;
  permissao: string;
  notifica: string;
  marcas: Marcas[];
  unidades: Unidades[];
}

export class Auth {
  public _id: string;
  constructor(
    public email: string,
    public nome: string,
    public newPassword: Boolean,
    public id: string,
    public contaAtual: AuthContas,
    public contas: AuthContas[],
    private _token: string,
    private _tokenExpirationDate: Date
  ) {}

  get token() {
    if (!this._tokenExpirationDate || new Date() > this._tokenExpirationDate) {
      return null;
    }
    return this._token;
  }
}
