import { Injectable } from "@angular/core";
import { Auth } from "../model/auth.model";
import { UserDataService } from "./userData.service";
import { Store } from "@ngrx/store";

import * as fromApp from "../../store/app.reducer";
import * as AuthActions from "../../pages/content/auth/store/auth.actions";

@Injectable({ providedIn: "root" })
export class ContaService {
  constructor(private _userDataService: UserDataService, private store: Store<fromApp.AppState>) {}

  alteraConta(auth: Auth) {
    const localUserRestored = this._userDataService.getUserData();
    localUserRestored.contaAtual = auth.contaAtual;
    this._userDataService.setUserData(auth);

    this.store.dispatch(new AuthActions.UpdateConta(auth.contaAtual));
    const { email, id: userId, _token: token, nome, newPassword, contas } = localUserRestored;
    this.store.dispatch(
      new AuthActions.AuthenticateSuccess({
        email,
        userId,
        token,
        expirationDate: localUserRestored._tokenExpirationDate,
        redirect: true,
        nome,
        newPassword,
        contaAtual: auth.contaAtual,
        contas,
      })
    );
  }
}
