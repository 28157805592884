import { Action } from "@ngrx/store";
import { Alcada } from "../../../../shared/model/alcada.model";
export const FETCH_ALCADAS = "[Alcadas] FETCH ALCADAS";
export const SET_ALCADAS = "[Alcadas] SET ALCADAS";
export const SET_ALCADA = "[Alcadas] SET ALCADA";
export const ADD_ALCADAS = "[Alcadas] ADD ALCADAS";
export const PUT_ALCADA = "[Alcadas] PUT ALCADAS";
export const UPDATE_ALCADA = "[Alcadas] UPDATE ALCADA";
export const FETCH_ALCADAS_EDIT = "[Alcadas] FETCH ALCADAS EDIT";
export const SET_ALCADAS_EDIT = "[Alcadas] SET ALCADAS EDIT";

export class FetchAlcadas implements Action {
  readonly type = FETCH_ALCADAS;
}

export class SetAlcada implements Action {
  readonly type = SET_ALCADA;

  constructor(public payload: Alcada) {}
}

export class SetAlcadas implements Action {
  readonly type = SET_ALCADAS;

  constructor(public payload: Alcada[]) {}
}

export class FetchAlcadaEdit implements Action {
  readonly type = FETCH_ALCADAS_EDIT;

  constructor(public payload: String) {}
}

export class SetAlcadaEdit implements Action {
  readonly type = SET_ALCADAS_EDIT;

  constructor(public payload: Alcada) {}
}

export class AddAlcada implements Action {
  readonly type = ADD_ALCADAS;

  constructor(public payload: Alcada) {}
}

export class PutAlcada implements Action {
  readonly type = PUT_ALCADA;

  constructor(public payload: Alcada) {}
}

export class UpdateAlcada implements Action {
  readonly type = UPDATE_ALCADA;

  constructor(public payload: Alcada) {}
}

export type AlcadasActions = FetchAlcadas | SetAlcadas | SetAlcada | FetchAlcadaEdit | SetAlcadaEdit | AddAlcada | PutAlcada | UpdateAlcada;
