import { Action } from "@ngrx/store";
import { Marcas } from "../../../../shared/model/marcas.model";

export const FETCH_MARCAS = "[MARCAS] = FETCH MARCAS";
export const SET_MARCAS = "[MARCAS] = SET MARCAS";
export const SET_MARCA = "[MARCAS] = SET MARCA";
export const REMOVE_MARCA = "[MARCAS] = REMOVE MARCA";
export const TOGGLE_FILTROMARCAS = "[Marcas] = TOGGLE FILTROS MARCAS";
export const ADD_MARCA = "[Marcas] = ADD MARCA";
export const UPDATE_MARCA = "[MARCAS] = UPDATE MARCA";

export class FetchMarcas implements Action {
  readonly type = FETCH_MARCAS;
}

export class SetMarcas implements Action {
  readonly type = SET_MARCAS;

  constructor(public payload: Marcas[]) {}
}

export class SetMarca implements Action {
  readonly type = SET_MARCA;

  constructor(public payload: Marcas) {}
}

export class RemoveMarca implements Action {
  readonly type = REMOVE_MARCA;
}

export class ToggleFiltro implements Action {
  readonly type = TOGGLE_FILTROMARCAS;
}

export class AddMarca implements Action {
  readonly type = ADD_MARCA;

  constructor(public payload: Marcas) {}
}

export class PutMarca implements Action {
  readonly type = UPDATE_MARCA;
  constructor(public payload: Marcas) {}
}

export type MarcasActions = FetchMarcas | SetMarcas | SetMarca | RemoveMarca | ToggleFiltro | AddMarca | PutMarca;
