import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { AuthService } from "./auth.service";
import { take, exhaustMap, map, catchError } from "rxjs/operators";
import { Store } from "@ngrx/store";
import * as fromApp from "./../../../store/app.reducer";
import * as AuthActions from "../auth/store/auth.actions";
import { Router } from "@angular/router";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService, private store: Store<fromApp.AppState>, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // this.authService.user.subscribe();
    return this.store.select("auth").pipe(
      take(1),
      map((authState) => {
        return authState.usuarioAuth;
      }),
      exhaustMap((user) => {
        if (!user) {
          return next.handle(req);
        }
        const modifiedReq = req.clone({
          headers: new HttpHeaders().set("Authorization", `Bearer ${user.token}`),
        });
        return next.handle(modifiedReq);
      }),
      catchError((response) => {
        console.log("response error");
        if (response.status === 401) {
          this.store.dispatch(new AuthActions.Logout());
        }

        return throwError(response);
      })
    );
  }
}
