import { Effect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { switchMap, map, share } from "rxjs/operators";

import { Estados } from "../model/estados.model";
import { Municipios } from "../model/municipios.model";
import * as fromApp from "../../store/app.reducer";
import * as SharedActions from "./shared.actions";
import * as DemandaActions from "../../pages/gestao/demanda/store/demanda.actions";
import { Demanda, Anexo } from "../model/demanda.model";
import { environment } from "environments/environment";

@Injectable()
export class SharedEffects {
  @Effect()
  fetchEstados = this.actions$.pipe(
    ofType(SharedActions.FETCH_ESTADOS),
    switchMap(() => {
      return this.http.get<Estados[]>("https://servicodados.ibge.gov.br/api/v1/localidades/estados");
    }),
    map((estados) => {
      return new SharedActions.SetEstados(estados);
    })
  );
  @Effect()
  fetchMunicipios = this.actions$.pipe(
    ofType(SharedActions.FETCH_MUNICIPIOS),
    switchMap(({ payload }) => {
      console.log(`id do estado: ${payload}`);
      return this.http.get<Municipios[]>(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${payload}/municipios`);
    }),
    map((municipios) => {
      return new SharedActions.SetMunicipios(municipios);
    })
  );

  @Effect()
  addAnexo = this.actions$.pipe(
    ofType(SharedActions.ADD_ANEXO),
    switchMap((sharedData: SharedActions.AddAnexo) => {
      const file = sharedData.payload.file;
      const formData = new FormData();
      for (let index = 0; index < file.length; index++) {
        const element = file[index];
        formData.append("uploads", element, element.name);
      }

      return this.http.post<Demanda>(`${environment.url_backend}/demandas/anexo/${sharedData.payload.id}`, formData);
    }),
    map((demanda) => {
      return new DemandaActions.SetDemanda(demanda);
    })
  );

  @Effect()
  downloadFile = this.actions$.pipe(
    ofType(SharedActions.DOWNLOAD_ANEXO),
    switchMap((sharedData: SharedActions.DownloadFile) => {
      const id = sharedData.payload;
      return this.http.get<Anexo>(`${environment.url_backend}/demandas/anexo/${id}`);
    }),
    map((file: Anexo) => {
      return new SharedActions.SetFile(file);
    })
  );

  constructor(private actions$: Actions, private http: HttpClient, private store: Store<fromApp.AppState>) {}
}
