import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { map, take } from "rxjs/operators";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private store: Store<fromApp.AppState>) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.store.select("auth").pipe(
      take(1),
      map((authState) => {
        return authState.usuarioAuth;
      }),
      map((usuario) => {
        const isAuth = !!usuario;

        if (isAuth) {
          return true;
        }
        return this.router.createUrlTree(["/content/auth"]);
      })
    );
  }
}
