import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";

import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentComponent } from "./layouts/content/content.component";
import { AuthGuard } from "./pages/content/auth/auth.guard";

const appRoutes: Routes = [
  {
    path: "",
    component: FullLayoutComponent,
    canActivate: [AuthGuard],
    children: Full_ROUTES,
  },
  {
    path: "",
    component: ContentComponent,
    children: CONTENT_ROUTES,
  },
  { path: "**", redirectTo: "gestao/unidades" },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
