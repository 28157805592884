import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";
import { environment } from "environments/environment";

@Injectable({ providedIn: "root" })
export class AESEncryptService {
  constructor() {}

  encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, environment.secretkey.trim()).toString();
  }

  decrypt(textToDecrypt: string) {
    return CryptoJS.AES.decrypt(textToDecrypt, environment.secretkey.trim()).toString(CryptoJS.enc.Utf8);
  }
}
