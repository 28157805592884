import { Action } from "@ngrx/store";

import { Estados } from "../model/estados.model";
import { Municipios } from "../model/municipios.model";
import { Anexo } from "../model/demanda.model";

export const FETCH_ESTADOS = "[SHARED] = FETCH ESTADOS";
export const SET_ESTADOS = "[SHARED] = SET ESTADOS";
export const FETCH_MUNICIPIOS = "[SHARED] = FETCH MUNICIPIOS";
export const SET_MUNICIPIOS = "[SHARED] = SET MUNICIPIOS";

export const ADD_ANEXO = "[SHARED] ADD ANEXO";
export const DOWNLOAD_ANEXO = "[SHARED] DOWNLOAD ANEXO";
export const SET_ANEXO = "[SHARED] SET ANEXO";
export const DELETE_FILE_TEMP = "[SHARED] SET DELETE FILE TEMP";
export const FINALIZA_LOADING = "[SHARED] FINALIZA LOADING";

export class FetchEstados implements Action {
  readonly type = FETCH_ESTADOS;
}
export class FetchMunicipios implements Action {
  readonly type = FETCH_MUNICIPIOS;

  constructor(public payload: string) {}
}
export class SetEstados implements Action {
  readonly type = SET_ESTADOS;

  constructor(public payload: Estados[]) {}
}
export class SetMunicipios implements Action {
  readonly type = SET_MUNICIPIOS;

  constructor(public payload: Municipios[]) {}
}

export class AddAnexo implements Action {
  readonly type = ADD_ANEXO;

  constructor(public payload: { id: string; file: File[] }) {}
}

export class DownloadFile implements Action {
  readonly type = DOWNLOAD_ANEXO;

  constructor(public payload: string) {}
}

export class SetFile implements Action {
  readonly type = SET_ANEXO;

  constructor(public payload: Anexo) {}
}

export class DeleteFileTemp implements Action {
  readonly type = DELETE_FILE_TEMP;
}

export class FinalizaLoading implements Action {
  readonly type = FINALIZA_LOADING;
}

export type SharedActions =
  | FetchEstados
  | FetchMunicipios
  | SetEstados
  | SetMunicipios
  | AddAnexo
  | DownloadFile
  | SetFile
  | DeleteFileTemp
  | FinalizaLoading;
