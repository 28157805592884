import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { of } from "rxjs";
import { Actions, ofType, Effect } from "@ngrx/effects";
import { HttpClient } from "@angular/common/http";
import { switchMap, catchError, map, tap } from "rxjs/operators";
import * as AuthActions from "./auth.actions";
import { environment } from "../../../../../environments/environment";
import { Auth } from "../../../../shared/model/auth.model";
import { AuthService } from "../auth.service";
import { Perfil } from "../../../../shared/model/perfil.model";
import { AESEncryptService } from "../../../../shared/services/AESEncrypt.Service";
import { UserDataService } from "app/shared/services/userData.service";

export interface AuthResponseData {
  idToken: string;
  email: string;
  refreshToken: string;
  expiresIn: string;
  localId: string;
  registered?: boolean;
  nome: string;
  perfil: string;
}

const handleAuthentication = (resData, aesService: AESEncryptService) => {
  console.log(`handleAuth: ${resData}`);
  const expirationDate = new Date(new Date().getTime() + 7 * 60 * 60 * 1000);
  const { token } = resData;
  const { email, id: userId, nome, newPassword, contas } = resData.usuario;
  let { contaAtual } = resData.usuario;
  if (!contaAtual) {
    contaAtual = contas.length === 1 ? { ...contas[0] } : null;
  }
  const user = new Auth(email, nome, newPassword, userId, contaAtual, contas, token, expirationDate);
  const data = aesService.encrypt(JSON.stringify(user));
  localStorage.setItem("userData", data);

  return new AuthActions.AuthenticateSuccess({
    email,
    userId,
    token,
    expirationDate,
    redirect: true,
    nome,
    newPassword,
    contaAtual,
    contas,
  });
};

const handleError = (errorRes) => {
  console.log(`handleError: ${errorRes}`);
  let errorMessage = "Algum erro desconhecido aconteceu!";
  if (!errorRes.error || !errorRes.error.error) {
    return of(new AuthActions.AuthenticateFail(errorMessage));
  }
  console.log("ERRO NO LOGIN");
  console.log(errorRes);
  if (errorRes.error instanceof String) {
    errorMessage = errorRes.error;
  } else {
    errorMessage = errorRes.error.error.message ? errorRes.error.error.message : errorRes.error.error;
  }

  return of(new AuthActions.AuthenticateFail(errorMessage));
};

@Injectable()
export class AuthEffects {
  @Effect()
  authSignup = this.actions$.pipe(
    ofType(AuthActions.SIGNUP_START),
    switchMap((signupAction: AuthActions.SignupStart) => {
      const { email, password } = signupAction.payload;
      return this.http
        .post<AuthResponseData>(`${environment.url_backend}/sessao`, {
          email,
          password,
          returnSecureToken: true,
        })
        .pipe(
          tap((resData) => {
            this.authService.setLogoutTimer(7 * 60 * 60 * 1000);
          }),
          map((resData) => handleAuthentication(resData, this._AESEncryptService)),
          catchError((errorRes) => handleError(errorRes))
        );
    })
  );

  @Effect()
  authLogin = this.actions$.pipe(
    ofType(AuthActions.LOGIN_START),
    switchMap((authData: AuthActions.LoginStart) => {
      const { email, password } = authData.payload;
      return this.http
        .post<AuthResponseData>(`${environment.url_backend}/sessao`, {
          email,
          password,
        })
        .pipe(
          tap((resData) => {
            this.authService.setLogoutTimer(7 * 60 * 60 * 1000);
          }),
          map((resData) => handleAuthentication(resData, this._AESEncryptService)),
          catchError((errorRes) => handleError(errorRes))
        );
    })
  );
  @Effect({ dispatch: false })
  authRedirect = this.actions$.pipe(
    ofType(AuthActions.AUTHENTICATE_SUCCESS),
    tap((authSuccessAction: AuthActions.AuthenticateSuccess) => {
      if (!authSuccessAction.payload.contaAtual) {
        this.router.navigate([`/content/selecionaconta`]);
      } else if (authSuccessAction.payload.newPassword) {
        this.router.navigate([`/content/newpassword/${authSuccessAction.payload.userId}`]);
      } else if (authSuccessAction.payload.redirect) {
        this.router.navigate(["/gestao/unidades"]);
      }
    })
  );

  @Effect()
  autoLogin = this.actions$.pipe(
    ofType(AuthActions.AUTO_LOGIN),
    map(() => {
      const userData = this._userDataService.getUserData();
      if (!userData) {
        return { type: "DUMMY" };
      }

      const loadedUser = new Auth(
        userData.email,
        userData.nome,
        userData.newPassword,
        userData.id,
        userData.contaAtual,
        userData.contas,
        userData._token,
        new Date(userData._tokenExpirationDate)
      );

      if (loadedUser.token) {
        // this.user.next(loadedUser);
        const { email, id: userId, token, nome, newPassword, contaAtual, contas } = loadedUser;

        const { _tokenExpirationDate: expirationDate } = userData;
        const expirationDuration = new Date(userData._tokenExpirationDate).getTime() - new Date().getTime();
        this.authService.setLogoutTimer(expirationDuration);

        return new AuthActions.AuthenticateSuccess({
          email,
          userId,
          token,
          expirationDate,
          redirect: false,
          nome,
          newPassword,
          contaAtual,
          contas,
        });
      }

      return { type: "DUMMY" };
    })
  );

  @Effect({ dispatch: false })
  authLogout = this.actions$.pipe(
    ofType(AuthActions.LOGOUT),
    tap(() => {
      this.authService.clearLogoutTimer();
      this._userDataService.removeData();
      this.router.navigate(["/content/auth"]);
    })
  );

  @Effect()
  authPasswordRecover = this.actions$.pipe(
    ofType(AuthActions.PASSWORD_RECOVER),
    switchMap((passwordAction: AuthActions.PasswordRecover) => {
      return this.http.get(`${environment.url_backend}/esquecisenha?email=${passwordAction.payload}`).pipe(
        map(() => {
          return new AuthActions.PasswordRecoverSuccess();
        }),
        catchError((errorRes) => handleError(errorRes))
      );
    })
  );

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private _AESEncryptService: AESEncryptService,
    private _userDataService: UserDataService
  ) {}
}
