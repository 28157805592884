import { Action } from "@ngrx/store";
import { Unidades } from "../../../../shared/model/unidades.model";

export const FETCH_UNIDADES = "[Unidades] FETCH UNIDADES";
export const FETCH_UNIDADES_EDIT = "[Unidades] FETCH UNIDADES EDIT";
export const SET_UNIDADES = "[Unidades] SET UNIDADES";
export const SET_UNIDADE = "[Unidades] SET UNIDADE";
export const SET_UNIDADE_EDIT = "[Unidades] SET UNIDADES EDIT";
export const REMOVE_UNIDADE_EDIT = "[Unidades] REMOVE UNIDADES EDIT";
export const STOP_LOADING_UNIDADES = "[Unidades] STOP LOADING UNIDADES";
export const ADD_UNIDADE = "[Unidades] ADD UNIDADE";
export const PUT_UNIDADE = "[Unidades] PUT UNIDADE";
export const UPDATE_UNIDADE = "[Unidades] UPDATE UNIDADE";
export const REVISAO_UNIDADE = "[Unidades] REVISAO UNIDADE";
export const UPDATE_REVISAO_UNIDADE = "[Unidades] UPDATE REVISAO UNIDADE";
export const REVISAO_REGULATORIO = "[Unidades] REVISAO REGULATORIO";
export const TOGGLE_FILTRO_GESTAO = "[Unidades] TOGGLE_FILTRO_GESTAO";

export class FetchUnidades implements Action {
  readonly type = FETCH_UNIDADES;
}

export class FetchUnidadesEdit implements Action {
  readonly type = FETCH_UNIDADES_EDIT;

  constructor(public payload: string) {}
}

export class SetUnidades implements Action {
  readonly type = SET_UNIDADES;

  constructor(public payload: Unidades[]) {}
}

export class StopLoad implements Action {
  readonly type = STOP_LOADING_UNIDADES;
}

export class AddUnidade implements Action {
  readonly type = ADD_UNIDADE;

  constructor(public payload: Unidades) {}
}

export class SetUnidade implements Action {
  readonly type = SET_UNIDADE;

  constructor(public payload: Unidades) {}
}

export class SetUnidadeEdit implements Action {
  readonly type = SET_UNIDADE_EDIT;

  constructor(public payload: Unidades) {}
}

export class PutUnidade implements Action {
  readonly type = PUT_UNIDADE;

  constructor(public payload: Unidades) {}
}
export class UpdateUnidade implements Action {
  readonly type = UPDATE_UNIDADE;

  constructor(public payload: Unidades) {}
}

export class RevisaoUnidade implements Action {
  readonly type = REVISAO_UNIDADE;

  constructor(public payload: { idOldRevisao: string; newRevisao: Unidades }) {}
}

export class RemoveUnidadeEdit implements Action {
  readonly type = REMOVE_UNIDADE_EDIT;
}

export class UpdateUnidadeRevisao implements Action {
  readonly type = UPDATE_REVISAO_UNIDADE;

  constructor(public payload: Unidades) {}
}

export class RevisaoRegulatorio implements Action {
  readonly type = REVISAO_REGULATORIO;

  constructor(public payload: Unidades) {}
}

export class ToggleFilroGestao implements Action {
  readonly type = TOGGLE_FILTRO_GESTAO;
}

export type UnidadesActions =
  | FetchUnidades
  | SetUnidade
  | StopLoad
  | AddUnidade
  | SetUnidades
  | SetUnidadeEdit
  | UpdateUnidade
  | PutUnidade
  | RevisaoUnidade
  | RemoveUnidadeEdit
  | UpdateUnidadeRevisao
  | RevisaoRegulatorio
  | ToggleFilroGestao;
