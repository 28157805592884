import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { HttpClient } from "@angular/common/http";

import * as AlcadaActions from "./alcadas.actions";
import { switchMap, map } from "rxjs/operators";
import { Alcada } from "../../../../shared/model/alcada.model";
import { environment } from "environments/environment";
import { UserDataService } from "app/shared/services/userData.service";

@Injectable()
export class AlcadasEffects {
  @Effect()
  fetchAlcada = this.actions$.pipe(
    ofType(AlcadaActions.FETCH_ALCADAS),
    switchMap(() => {
      const usuario = this._userDataService.getUserData();
      return this.http.get<Alcada[]>(`${environment.url_backend}/alcadas?_conta=${usuario.contaAtual.conta._id}`);
    }),
    map((alcadas) => {
      return alcadas.map((alcada) => {
        return {
          ...alcada,
          id: alcada._id,
        };
      });
    }),
    map((alcadas) => {
      return new AlcadaActions.SetAlcadas(alcadas);
    })
  );

  @Effect()
  fetchAlcadaEdit = this.actions$.pipe(
    ofType(AlcadaActions.FETCH_ALCADAS_EDIT),
    switchMap(({ payload }) => {
      return this.http.get<Alcada>(`${environment.url_backend}/alcadas?_alcada=${payload}`);
    }),
    map((alcada) => {
      return new AlcadaActions.SetAlcadaEdit(alcada);
    })
  );

  @Effect()
  addAlcada = this.actions$.pipe(
    ofType(AlcadaActions.ADD_ALCADAS),
    switchMap(({ payload }) => {
      return this.http.post<Alcada>(`${environment.url_backend}/alcadas`, payload);
    }),
    map((alcada) => {
      return new AlcadaActions.SetAlcada(alcada);
    })
  );

  @Effect()
  putAlcada = this.actions$.pipe(
    ofType(AlcadaActions.PUT_ALCADA),
    switchMap(({ payload }) => {
      const { _id } = payload;
      return this.http.put<Alcada>(`${environment.url_backend}/alcadas/${_id}`, payload);
    }),
    map((alcada) => {
      return new AlcadaActions.UpdateAlcada(alcada);
    })
  );

  constructor(private actions$: Actions, private http: HttpClient, private _userDataService: UserDataService) {}
}
