import { Alcada } from "../../../../shared/model/alcada.model";
import * as AlcadaActions from "./alcadas.actions";

export interface State {
  alcadas: Alcada[];
  alcada: Alcada;
  concluido: Boolean;
}

const initialState: State = {
  alcadas: [],
  alcada: null,
  concluido: false,
};

export function alcadaReducer(state = initialState, action: AlcadaActions.AlcadasActions) {
  switch (action.type) {
    case AlcadaActions.FETCH_ALCADAS:
      return {
        ...state,
        concluido: false,
      };
    case AlcadaActions.SET_ALCADAS:
      return {
        ...state,
        alcadas: [...action.payload],
        concluido: false,
      };
    case AlcadaActions.SET_ALCADA:
      return {
        ...state,
        alcadas: [...state.alcadas, action.payload],
        concluido: true,
      };
    case AlcadaActions.SET_ALCADAS_EDIT:
      return {
        ...state,
        alcada: action.payload,
        concluido: false,
      };
    case AlcadaActions.UPDATE_ALCADA:
      const index = state.alcadas.findIndex((elem, index, array) => {
        return action.payload._id === elem._id;
      });

      const updateAlcada = {
        ...state.alcadas[index],
        ...action.payload,
      };
      const updatedAlcadas = [...state.alcadas];
      updatedAlcadas[index] = updateAlcada;
      return {
        ...state,
        alcadas: updatedAlcadas,
        concluido: true,
      };
    case AlcadaActions.ADD_ALCADAS:
      return {
        ...state,
        concluido: false,
      };

    default:
      return state;
  }
}
