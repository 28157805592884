import { Municipios } from "../model/municipios.model";
import * as SharedActions from "./shared.actions";
import { Estados } from "../model/estados.model";
import { Anexo } from "../model/demanda.model";
import { Usuarios } from "../model/usuarios.model";
import { Relatorio } from "../model/relatorio.model";

export interface State {
  estados: Estados[];
  municipios: Municipios[];
  loadingMunicipios: boolean;
  usuarios: Usuarios[];
  loading: boolean;
  fileTemp: Anexo;
}

const initialState: State = {
  estados: [],
  municipios: [],
  loadingMunicipios: false,
  usuarios: [],
  loading: false,
  fileTemp: null,
};

export function sharedReducer(state = initialState, action: SharedActions.SharedActions) {
  switch (action.type) {
    case SharedActions.DOWNLOAD_ANEXO:
      return {
        ...state,
        loading: true,
      };
    case SharedActions.SET_ESTADOS:
      return {
        ...state,
        estados: [...action.payload],
        loading: false,
      };
    case SharedActions.SET_MUNICIPIOS:
      return {
        ...state,
        loadingMunicipios: false,
        municipios: [...action.payload],
        loading: false,
      };
    case SharedActions.FETCH_MUNICIPIOS:
      return {
        ...state,
        loadingMunicipios: true,
        loading: true,
      };

    case SharedActions.SET_ANEXO:
      return {
        ...state,
        loading: false,
        fileTemp: action.payload,
      };
    case SharedActions.DELETE_FILE_TEMP:
      return {
        ...state,
        fileTemp: null,
      };
    case SharedActions.FINALIZA_LOADING:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
