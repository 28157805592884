import { Demanda } from "../../../../shared/model/demanda.model";
import * as DemandaActions from "./demanda.actions";

export interface State {
  demandas: Demanda[];
  demanda: Demanda;
  loading: boolean;
  filtro: boolean;
  fimProcessamento: boolean;
}

const initialState: State = {
  demandas: [],
  demanda: null,
  loading: false,
  filtro: true,
  fimProcessamento: false,
};

export function demandaReducer(state = initialState, action: DemandaActions.DemandaActions) {
  switch (action.type) {
    case DemandaActions.FETCH_DEMANDAS || DemandaActions.PUT_DEMANDA || DemandaActions.FETCH_DEMANDA:
      return {
        ...state,
        loading: true,
      };

    case DemandaActions.SET_DEMANDAS:
      return {
        ...state,
        loading: false,
        demandas: [...action.payload],
      };
    case DemandaActions.SET_DEMANDA:
      const index = state.demandas.findIndex((elem, index, array) => {
        return action.payload._id === elem._id;
      });
      const updateDemanda = {
        ...state.demandas[index],
        ...action.payload,
      };
      const updateDemandas = [...state.demandas];
      updateDemandas[index] = updateDemanda;
      return {
        ...state,
        loading: false,
        demanda: action.payload,
        demandas: [...updateDemandas],
      };
    case DemandaActions.REMOVE_DEMANDA:
      return {
        ...state,
        loading: false,
        demanda: null,
        fimProcessamento: true,
      };
    case DemandaActions.FIM_PROCESSAMENTO:
      return {
        ...state,
        fimProcessamento: true,
      };
    case DemandaActions.TOGGLE_FILTRO_DEMANDA:
      return {
        ...state,
        filtro: !state.filtro,
      };
    case DemandaActions.RESETA_PROCESSAMENTO:
      return {
        ...state,
        fimProcessamento: false,
      };
    default:
      return state;
  }
}
