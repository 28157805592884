import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { HttpClient } from "@angular/common/http";
import { switchMap, map } from "rxjs/operators";

import { Grupo } from "../../../../shared/model/grupo.model";
import * as GrupoActions from "./grupos.actions";
import { environment } from "environments/environment";
import { UserDataService } from "app/shared/services/userData.service";

@Injectable()
export class GruposEffects {
  @Effect()
  fetchGrupos = this.actions$.pipe(
    ofType(GrupoActions.FETCH_GRUPOS),
    switchMap(() => {
      const usuario = this._userDataService.getUserData();
      return this.http.get<Grupo[]>(`${environment.url_backend}/grupos?_conta=${usuario.contaAtual.conta._id}`);
    }),
    map((grupos) => {
      return grupos.map((grupo) => {
        return {
          ...grupo,
          id: grupo._id,
        };
      });
    }),
    map((grupos) => {
      return new GrupoActions.SetGrupos(grupos);
    })
  );

  @Effect()
  storeGrupo = this.actions$.pipe(
    ofType(GrupoActions.ADD_GRUPO),
    switchMap(({ payload }) => {
      return this.http.post<Grupo>(`${environment.url_backend}/grupos`, payload);
    }),
    map((grupo) => {
      return new GrupoActions.SetGrupo(grupo);
    })
  );

  @Effect()
  updateGrupo = this.actions$.pipe(
    ofType(GrupoActions.PUT_GRUPO),
    switchMap(({ payload }) => {
      const { id } = payload;
      return this.http.put<Grupo>(`${environment.url_backend}/grupos/${id}`, payload);
    }),
    map((grupo) => {
      return new GrupoActions.UpdateGrupo(grupo);
    })
  );

  @Effect()
  fetchGrupoEdit = this.actions$.pipe(
    ofType(GrupoActions.FETCH_GRUPO_EDIT),
    switchMap(({ payload }) => {
      return this.http.get<Grupo>(`${environment.url_backend}/grupos?_grupo=${payload}`);
    }),
    map((grupo) => {
      return new GrupoActions.SetGrupoEdit(grupo);
    })
  );

  constructor(private actions$: Actions, private http: HttpClient, private _userDataService: UserDataService) {}
}
