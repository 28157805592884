import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { HttpClient } from "@angular/common/http";

import { Unidades } from "../../../../shared/model/unidades.model";
import { map, switchMap, tap, catchError, take } from "rxjs/operators";
import { of } from "rxjs";
import { environment } from "environments/environment";
import * as UnidadesActions from "./unidades.actions";
import { UserDataService } from "app/shared/services/userData.service";

@Injectable()
export class UnidadesEffects {
  @Effect()
  fetchUnidades = this.actions$.pipe(
    ofType(UnidadesActions.FETCH_UNIDADES),
    switchMap((payload: UnidadesActions.FetchUnidades) => {
      const usuario = this._userDataService.getUserData();
      return this.http.get<Unidades[]>(`${environment.url_backend}/unidades?_conta=${usuario.contaAtual.conta._id}`).pipe(
        map((unidades) => {
          return new UnidadesActions.SetUnidades(unidades);
        }),
        catchError((error) => {
          return of(new UnidadesActions.StopLoad());
        })
      );
    })
  );

  @Effect()
  fetchUnidadeEdit = this.actions$.pipe(
    ofType(UnidadesActions.FETCH_UNIDADES_EDIT),
    switchMap(({ payload }) => {
      return this.http.get<Unidades>(`${environment.url_backend}/unidades?_unidade=${payload}`);
    }),
    map((unidade) => {
      return new UnidadesActions.SetUnidadeEdit(unidade);
    })
  );

  @Effect()
  updateUnidade = this.actions$.pipe(
    ofType(UnidadesActions.PUT_UNIDADE),
    switchMap(({ payload }) => {
      const { id } = payload;
      return this.http.put<Unidades>(`${environment.url_backend}/unidades/${id}`, payload);
    }),
    map((unidade) => {
      return new UnidadesActions.UpdateUnidade(unidade);
    })
  );

  @Effect()
  addUnidade = this.actions$.pipe(
    ofType(UnidadesActions.ADD_UNIDADE),
    switchMap(({ payload }) => {
      return this.http.post<Unidades>(`${environment.url_backend}/unidades`, payload);
    }),
    map((unidade) => {
      return new UnidadesActions.SetUnidade(unidade);
    })
  );

  @Effect()
  revisaoUnidade = this.actions$.pipe(
    ofType(UnidadesActions.REVISAO_UNIDADE),
    switchMap((unidadesData: UnidadesActions.RevisaoUnidade) => {
      const { idOldRevisao, newRevisao } = unidadesData.payload;
      return this.http.put<Unidades>(`${environment.url_backend}/revisao/unidade/${idOldRevisao}`, newRevisao);
    }),
    map((unidade) => {
      return new UnidadesActions.UpdateUnidadeRevisao(unidade);
    })
  );

  @Effect()
  revisaoRegulatorio = this.actions$.pipe(
    ofType(UnidadesActions.REVISAO_REGULATORIO),
    switchMap((unidadeData: UnidadesActions.RevisaoRegulatorio) => {
      const { id } = unidadeData.payload;
      return this.http.put<Unidades>(`${environment.url_backend}/revisao/regulatorio/${id}`, unidadeData.payload);
    }),
    map((unidade) => {
      return new UnidadesActions.UpdateUnidade(unidade);
    })
  );

  constructor(private actions$: Actions, private http: HttpClient, private _userDataService: UserDataService) {}
}
